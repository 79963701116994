import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import UnorderedList from "~/components/List/Unordered";
import Footnote from "~/components/footnote/footnote";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={3}
        lesson={"Lesson 1"}
        color={"ms"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 3</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>People may spend a lot of time looking at websites. Often, information on one website is very different from information on another website. After looking at a site, have you ever felt like you wasted your time because you did not find what you were looking for? Have you spent a long time looking at a site before figuring out that the information is confusing or wrong? How did it affect you getting the information you wanted?</p>
              <p>People get a lot of information about their health from the internet. This information can be hard to make sense of. According to the Centers for Disease Control and Prevention (CDC), as many as 9 out of 10 adults have trouble understanding health information.<sup>1</sup> The problem is worse when</p>

              <UnorderedList>
                <li>the topic is new to them,</li>
                <li>the information is difficult, or</li>
                <li>there is a lot of jargon. (Jargon is special words or phrases used by specific professions that most people do not know.)</li>
              </UnorderedList>

              <p>If people do not understand the information, they may make poor decisions.</p>

              <Footnote
                author="Centers for Disease Control & Prevention. (2019)."
                footnoteNumber="1"
                href="https://www.cdc.gov/healthliteracy/shareinteract/TellOthers.html"
                source="Retrieved from"
                title="Talking points about health literacy."
              />
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>The first part in this step serves as a transition to help students begin thinking about their previous experiences searching the internet. It is likely that students have been frustrated by confusing sites. They may or may not recognize that some sites are inaccurate.</p>
              <p>In later steps (and in the rest of the lessons in this module), students learn skills for analyzing information (including accuracy) they see on websites and other media sources. The information on this screen introduces them to the fact that many people have difficulty analyzing and understanding information they find on websites. This step is intended to have students start thinking about the importance of paying attention to website quality and the fact that this can be a difficult skill for many people.</p>
              <p>Although your students may feel that they already know how to evaluate internet information, studies have shown that middle school and high school students are often easily duped. In one study, students had difficulty with even basic skills such as determining whether a website was a credible source or an ad.<sup>1</sup></p>

              <Footnote
                author="Breakstone, J., McGrew, S., Smith, M., Ortega, T., & Wineburg, S. (2018)."
                footnoteNumber="1"
                href="https://www.socialstudies.org/publications/socialeducation/september2018/teaching-students-to-navigate-online-landscape"
                source="Social Education, 82(4), pp. 219–221,"
                title="Teaching Students to Navigate the Online Landscape."
              />
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
